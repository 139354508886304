function openNav() {
    const sidebarConteiner = document.getElementById("sidebar-container");
    if(sidebarConteiner){
        window.sessionStorage.setItem('opened', true);
        document.getElementById("sidebar-container").style.width = "320px";
        document.getElementById("content").style.paddingLeft = "320px";
        document.getElementById("btnopen").style.display = "none";
        document.getElementById("btnclose").style.display = "block";
    }
}

function closeNav() {
    window.sessionStorage.setItem('opened', false);
    document.getElementById("sidebar-container").style.width = "60px";
    document.getElementById("content").style.paddingLeft = "60px";
    document.getElementById("btnopen").style.display = "block";
    document.getElementById("btnclose").style.display = "none";

}
